<template>
  <section v-title data-title="我的">
    <div>
      <span class="span-title">电话</span>
      <span class="span-content">15620621788</span>
      <span class="span-title">QQ</span>
      <span class="span-content">840933526</span>
      <span class="span-title">微信</span>
      <span class="span-content">gaoxiaoce</span>
      <span class="span-title">邮箱</span>
      <span class="span-content">huihuangzbgxc@icloud.com</span>
      <span class="span-title">地址</span>
      <span class="span-content">天津市静海区大邱庄镇黄山路2号</span>
    </div>
  </section>
</template>

<script>
export default {
  name: "contract",
};
</script>

<style >
* {
  font-family: "SourceHanSansCN";
}
section {
  display: flex;
  justify-content: center;
}

section > div {
  padding: 50px 0px;
  min-width: 1280px;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  font-size: 18px;
  color: #333;
}

.span-title::before {
  content: "";
  display: inline-block;
  height: 14px;
  width: 5px;
  border-radius: 3px;
  background-color: rgb(31, 169, 219);
  margin-right: 10px;
}

.span-title {
  font-family: "WenQuanYi";
  color: rgb(31, 169, 219);
  margin-top: 30px;
}
</style>